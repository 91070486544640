import React from 'react'
import { Box, Grid } from '@mui/material'
import { Button } from 'gatsby-material-ui-components'
import useForm from '@hooks/useForm'
import {
  FormAddCredentials,
  initialValues,
  initialValidation,
  TFormAddCredentials
} from '@components/Form/FormAddCredentials'
import { createCredential } from '@utils/api/credentials'
import GenericFormPage from '@templates/Generic/Form'
import useApi from '@hooks/useApi'
import { navigate } from 'gatsby'

const HostsCredentialsAddPage = () => {
  const api = useApi({ apiMethod: createCredential, requestOnMount: false })
  const form = useForm<TFormAddCredentials>({
    initialValues,
    initialValidationRules: initialValidation
  })

  return (
    <GenericFormPage
      maxWidth="md"
      title="Add Credential"
      breadcrumbs={[
        {
          title: 'Hosts',
          to: '/hosts/'
        },
        {
          title: 'Credentials',
          to: '/hosts/credentials/'
        },
        {
          title: 'Add Credential'
        }
      ]}
      form={form as any}
      api={api}
      successMessage="Credential Successfully Added."
      autoComplete="credentials-add-form"
      formActions={
        <Grid container spacing={2}>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={api.loading}
            >
              {'Add Credentials'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="text"
              onClick={() => window !== undefined && window.history.back()}
            >
              {'Cancel'}
            </Button>
          </Grid>
        </Grid>
      }
      completeStepActions={(setComplete) => (
        <Grid container spacing={2}>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => navigate('/hosts/credentials/')}
            >
              {'Go to Credentials'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              onClick={() => {
                form.reset()
                setComplete(false)
              }}
            >
              Add Another
            </Button>
          </Grid>
        </Grid>
      )}
    >
      <FormAddCredentials form={form as any} />
    </GenericFormPage>
  )
}

export default HostsCredentialsAddPage
